<template>
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      ok-title="Lưu"
      cancel-title="Hủy bỏ"
      centered
      size="lg"
      no-close-on-backdrop
      :title="title"
      @ok="accept($event)"
      @hidden="resetData"
    >
      <validation-observer ref="rule">

        <b-form>
          <b-row>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Họ và tên<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                  :custom-messages="name"
                >
                  <b-form-input
                    v-model="dataInput.name"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Ngày sinh<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="birthDay"
                  rules="required"
                  :custom-messages="birthDay"
                >
                  <date-time-picker
                    v-model="dataInput.birthDay"
                    :disabledInput="true"
                    :disabled="disableProp"
                    @input="() => {}"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="gender"
                  rules="required"
                  :custom-messages="gender"
                >
                  <label>Giới tính<span class="required">(*)</span></label>
                  <div class="d-flex mt-1">
                    <b-form-radio
                      v-model="dataInput.gender"
                      class="mr-1"
                      :value="true"
                    >
                      Nam
                    </b-form-radio>
                    <b-form-radio
                      v-model="dataInput.gender"
                      :value="false"
                    >
                      Nữ
                    </b-form-radio>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label for="code">Tỉnh/Thành phố<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="city"
                  rules="required"
                  :custom-messages="city"
                >
                  <v-select
                    v-model="dataInput.provinceId"
                    :reduce="item => item.id"
                    label="name"
                    :options="provinceList || []"
                    placeholder="Lựa chọn tỉnh/Thành phố"
                    @input="dataDistrict"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label for="code">Quận/Huyện<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="district"
                  rules="required"
                  :custom-messages="district"
                >
                  <v-select
                    v-model="dataInput.districtId"
                    :reduce="item => item.id"
                    label="name"
                    :disabled="isDisableDistrict"
                    :options="districtList || []"
                    placeholder="Lựa chọn quận/Huyện"
                    @input="dataWard"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label for="code">Phường/Xã<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Ward"
                  rules="required"
                  :custom-messages="Ward"
                >
                  <v-select
                    v-model="dataInput.wardId"
                    :reduce="item => item.id"
                    label="name"
                    :disabled="isDisableWard"
                    :options="wardList || []"
                    placeholder="Lựa chọn phường/xã"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Địa chỉ</label>
                <b-form-input
                  v-model="dataInput.address"
                />
              </b-form-group>
            </b-col>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Số điện thoại<span class="required">(*)</span></label>
                <b-form-input
                  v-model="dataInput.phoneNumber"
                />
              </b-form-group>
            </b-col>
            <b-col
              xl="4"
              lg="4"
              md="12"
              sm="12"
            >
              <b-form-group>
                <label>Email</label>
                <b-form-input
                  v-model="dataInput.email"
                  type="email"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group>
            <label for="code">Thông tin</label>
            <b-form-textarea
              v-model="dataInput.information"
              no-resize
            >
            </b-form-textarea>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>

import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BRow,
  BCol,
  BFormRadioGroup,
  BFormRadio,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import axios from 'axios'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormRadioGroup,
    BFormRadio,
    DateTimePicker,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      required,
      name: {
        required: 'Họ và tên là bắt buộc',
      },
      birthDay: {
        required: 'Ngày sinh là bắt buộc',
      },
      gender: {
        required: 'Giới tính là bắt buộc',
      },
      city: {
        required: 'Tỉnh/Thành phố là bắt buộc',
      },
      district: {
        required: 'Quận/Huyện là bắt buộc',
      },
      Ward: {
        required: 'Phường/Xã là bắt buộc',
      },
      dataInput: {
        name: '',
        birthDay: '',
        gender: true,
        address: '',
        provinceId: '',
        districtId: '',
        wardId: '',
        phoneNumber: '',
        email: '',
        information: '',
      },
      provinceList: [],
      districtList: [],
      wardList: [],
      isDisableDistrict: true,
      isDisableWard: true,
      disableProp: false,
    }
  },
  watch: {
    async dataDetail(val) {
      if (val && this.type === 'edit') {
        this.dataInput = {
          name: this.dataDetail.name,
          birthDay: this.dataDetail.birthDay,
          gender: this.dataDetail.gender,
          address: this.dataDetail.address,
          provinceId: this.dataDetail.provinceId,
          districtId: this.dataDetail.districtId,
          wardId: this.dataDetail.wardId,
          phoneNumber: this.dataDetail.phoneNumber,
          email: this.dataDetail.email,
          information: this.dataDetail.information,
        }
        await this.dataDistrict()
        await this.dataWard()
      }
    },
  },

  created() {
    this.fetchComboboProvince()
  },
  methods: {
    accept(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },

    // Tỉnh thành phố
    async fetchComboboProvince() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_CITY)
      this.provinceList = data
    },
    dataDistrict(val) {
      if (val !== undefined) {
        this.isDisableDistrict = false
        this.dataInput.districtId = ''
        this.dataInput.wardId = ''
        this.fetchComboboxDistrict(this.dataInput.provinceId)
      } else if (this.dataInput.provinceId) {
        this.isDisableDistrict = false
        this.fetchComboboxDistrict(this.dataInput.provinceId)
      } else {
        this.dataInput.districtId = ''
        this.dataInput.wardId = ''
        this.isDisableDistrict = true
        this.isDisableWard = true
      }
    },

    // Quận huyện
    async fetchComboboxDistrict(a) {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.COMBOBOX_DISTRICT}${a}`)
      this.districtList = data
    },

    dataWard(val) {
      if (val !== undefined) {
        this.isDisableWard = false
        this.dataInput.wardId = ''
        this.fetchComboboxWard(this.dataInput.districtId)
      } else if (this.dataInput.districtId) {
        this.isDisableWard = false
        this.fetchComboboxWard(this.dataInput.districtId)
      } else {
        this.dataInput.wardId = ''
        this.isDisableWard = true
      }
    },

    // Xã phường
    async fetchComboboxWard(a) {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.COMBOBOX_WARD}${a}`)
      this.wardList = data
    },

    resetData() {
      this.dataInput = {
        name: '',
        birthDay: '',
        gender: true,
        address: '',
        provinceId: '',
        districtId: '',
        wardId: '',
        phoneNumber: '',
        email: '',
        information: '',
      }
    },
  },
}
</script>
<style lang="scss">
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
</style>
